import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    background: theme.palette.secondary.main,
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));
const Tag = ({ name }) => {
  const classes = useStyles();
  return (
    <>
      <Box my={1} display="flex" flexWrap="wrap">
        <Paper className={classes.paper}>
          <Typography style={{ margin: 0 }} variant="caption" gutterBottom>
            {`#${name}`}
          </Typography>
        </Paper>
      </Box>
    </>
  );
};

export default Tag;
